body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-image: url('../src/assets/stem_tech_network_earth_social_network_learning_educational.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 10vmin;
  margin-bottom: 5%;
}

.App-logo-footer {
  height: 5vh;
  min-height: 45px;
  margin-top: 2vh;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.app-icon {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 30vmin;
  border-radius: 10px;
}

.title {
  color: #fff;
  font-weight: 600;
}

.stemTitle {
  font-size: 7vh;
  margin-top: 0;
}

.techNetwork {
  color: #fff;
  margin-top: -30;
}

.subtitle {
  margin-top: -2vh;
}

.comingSoon {
  margin-top: -1vh;
  font-size: smaller;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.flexed {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #1c1c1c;
  color: white;
  text-align: center;
}

.centerEverything {
  justify-self: 'center';
  align-self: 'center';
  text-align: 'center';
  align-content: 'center';
  justify-content: 'center';
  align-items: 'center';
  vertical-align: 'center';
  justify-items: 'center'
}

.blackBox {
  background-color: '#1c1c1c70';
  padding-left: 25;
  padding-right: 25;
  padding-top: 10;
  padding-bottom: 10;
  margin-top: 15;
  margin-bottom: 15;
  border-radius: 10;
}

.headerSection {
  width: 50vh;
}

.footerSection {
  flex-direction: column;
}

header {
  width: 100%;
  background-color: #00000099;
  color: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.screenBody {
  min-height: 100vh;
}